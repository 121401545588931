const MainMenuConfig: any[] = [
    {
        pages: [
            {
                heading: `login`,
                route: `/login`,
                publicRoute: true,
                keenthemesIcon: `lock-2`
            },
            {
                heading: `dashboard`,
                route: `/dashboard`,
                publicRoute: false,
                keenthemesIcon: `element-11`
            },
            {
                heading: `users`,
                route: `/users`,
                publicRoute: false,
                keenthemesIcon: `user`
            },
            {
                heading: `scriptures`,
                route: `/scriptures`,
                publicRoute: false,
                keenthemesIcon: `tablet-book`
            },
            {
                heading: `socialAccounts`,
                route: `/social`,
                publicRoute: false,
                keenthemesIcon: `facebook`
            },
            {
                heading: `socialPosts`,
                route: `/posts`,
                publicRoute: false,
                keenthemesIcon: `messages`
            },
            {
                heading: `imageTemplates`,
                route: `/image-templates`,
                publicRoute: false,
                keenthemesIcon: `picture`
            }
        ]
    }
]

export default MainMenuConfig
